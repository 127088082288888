@import "src/styles/variables";

.wrapper {
  max-width: 800px;
  margin: auto;
  padding-bottom: 200px;
}

.title {
  display: block;
  padding: 60px 0;
  font-size: 50px;
  text-align: center;

  @include mobile {
    font-size: 40px;
    padding-top: 75px;
    padding-bottom: 30px;
  }
}

.input {
  display: block;
  width: 500px;
  margin: 0 auto 50px;

  @include mobile {
    width: 85vw;
    margin-bottom: 35px;
  }
}

.add {
  display: block;
  position: absolute;
  top: -1px;
  right: 0px;
  width: 100px;
  height: 100px;
  border: 1px solid $line;
  border-right: none;

  &:hover {
    border-color: $grey;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 3px;
    height: 50px;
    margin: auto;
    background-color: $grey;

    @include mobile {
      height: 23px;
    }
  }

  &::after {
    transform: rotate(90deg);
  }

  @include mobile {
    width: 50px;
    height: 50px;
  }
}
