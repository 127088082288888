// Imports
@import "variables";

// Global
html {
  // Font
  color: $black;
  font-size: 14px;
  font-family: $font;
  overflow-y: scroll;
}

// Scrollbar
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: $background;
}
::-webkit-scrollbar-thumb {
  background: lighten($grey, 10);
  &:hover {
    background: $grey;
  }
}

// Inputs
input,
textarea {
  width: 100%;
  padding: 10px 20px;
  font-size: 18px;
  border: 1px solid $grey;
  text-align: left;
}

textarea {
  min-height: 250px;
  padding: 20px;
  font-size: 16px;
}

ul {
  list-style-type: disc;

  ul {
    list-style-type: circle;
  }
}
ol {
  list-style-type: decimal;
}
