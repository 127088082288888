@import "src/styles/variables";

.button {
  margin-top: 30px;
  padding: 10px 20px;
  color: $grey;
  font-size: 16px;
  font-weight: normal;
  border: 1px solid $line;

  &:hover {
    color: $darkGrey;
    border-color: $grey;
  }

  @include mobile {
    position: absolute;
    margin-top: 65px;
    left: 50%;
    transform: translateX(-50%);
  }
}
