@import "src/styles/variables";

.wrapper {
  @include mobile {
    margin: 0 20px;
  }
}

.title {
  font-size: 26px;
  font-weight: bold;
}

.edit {
  margin-left: 20px;
  padding: 5px 10px;
  color: $grey;
  font-size: 16px;
  font-weight: normal;
  border: 1px solid $line;

  &:hover {
    color: $darkGrey;
    border-color: $grey;
  }

  @include mobile {
    margin-top: 7px;
    float: right;
  }
}

.date {
  margin: 10px 0 40px;
  color: $grey;
}

.delete {
  margin-top: 100px;
  color: $grey;
  font-size: 13px;

  &:hover {
    color: $red;
  }
}
