@import "src/styles/variables";

.wrapper {
  display: inline-block;
  margin-bottom: 50px;
  padding: 10px 20px;
  color: $grey;
  border: 1px solid $line;

  &:hover {
    color: $black;
    border-color: $grey;
  }

  @include mobile {
    display: block;
    width: 82px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
}
