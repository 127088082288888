@import "src/styles/variables";

.title {
  margin-bottom: 30px;
  font-size: 30px;
  text-align: center;
}

.label {
  display: block;
  margin-bottom: 30px;

  p {
    margin-bottom: 10px;
    font-size: 16px;
  }

  @include mobile {
    width: 90vw;
    margin: 0 auto;
    margin-bottom: 30px;
  }
}

.submit {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid $grey;

  &:hover,
  &:focus {
    color: white;
    border-color: $purple;
    background-color: $purple;
  }

  @include mobile {
    display: block;
    margin: 0 auto;
  }
}

.preview {
  margin: 40px 0 20px;
  color: $grey;
}
