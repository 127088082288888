@import "src/styles/variables";

.wrapper {
  display: flex;
  align-items: center;
  margin-top: 30px;
  float: right;

  @include mobile {
    margin-right: 20px;
    margin-top: 7px;
  }
}

.center {
  width: 300px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 7px;
}

.listes {
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
  margin: 0 7px;
  li {
    display: flex;
    width: 27px;
    text-align: center;
    user-select: none;
    cursor: pointer;

    a {
      width: 100%;
      padding: 7px 0;
      font-size: 15px;
    }

    &:hover {
      background-color: #f7f7f7;
      color: $purple;
    }
  }
}

.point {
  transition: 0.2s opacity;
}
.max {
  width: 27px;
  padding: 7px 0;
  text-align: center;
  font-size: 15px;
  margin-left: 7px;
  transition: 0.2s opacity;
  cursor: pointer;

  &:hover {
    background-color: #f7f7f7;
    color: $purple;
  }
}

.min {
  width: 27px;
  padding: 7px 0;
  text-align: center;
  font-size: 15px;
  margin-right: 7px;
  transition: 0.2s opacity;
  cursor: pointer;
  &:hover {
    background-color: #f7f7f7;
    color: $purple;
  }
}

.hidden {
  opacity: 0;
  display: none;
}

.activeIndex {
  color: $purple;
  font-weight: bold;
}

.btnNext {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 1px;
  width: 30px;
  padding: 17px 0;
  transition: background-color 0.2s;
  &:hover {
    background-color: #f7f7f7;
  }

  &:hover {
    color: $darkGrey;
    border-color: $grey;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -4px;
    right: 0;
    bottom: 0;
    width: 10px;
    height: 10px;
    margin: auto;
    border: 2px solid $purple;
    border-right: none;
    border-bottom: none;
    transform: rotate(135deg);
  }
}

.btnPrev {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 1px;
  transition: background-color 0.2s;
  width: 30px;
  padding: 17px 0;

  &:hover {
    background-color: #f7f7f7;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 4px;
    right: 0;
    bottom: 0;
    width: 10px;
    height: 10px;
    margin: auto;
    border: 2px solid $purple;
    border-right: none;
    border-bottom: none;
    transform: rotate(-45deg);
  }
}

.filter {
  cursor: initial;
  opacity: 0.3;
}
