@import "src/styles/variables";

.post {
  display: block;
  align-items: center;
  position: relative;
  margin: 10px 0;
  padding: 20px;
  border: 1px solid $line;

  &:hover {
    border-color: $grey;
  }

  @include mobile {
    width: 90vw;
    min-height: 130px;
    margin: 20px auto;
  }
}

.title {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: bold;
}

.date {
  position: absolute;
  top: 20px;
  right: 20px;
  color: $grey;
  font-size: 13px;

  @include mobile {
    bottom: 20px;
    top: initial;
  }
}
