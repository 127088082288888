@import "src/styles/variables";

.btn {
  margin-left: 20px;
  padding: 5px 10px;
  color: $grey;
  font-size: 16px;
  font-weight: normal;
  border: 1px solid $line;

  &:hover {
    color: $darkGrey;
    border-color: $grey;
  }

  @include mobile {
    margin-top: 7px;
    float: right;
  }
}
