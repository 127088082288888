@import "mixins";

$pink: #f5b3a5;
$purple: #6a4796;
$lightGreen: #86d723;

// Main colors
$red: #d0021b;
$black: #000;

// Greys
$darkGrey: #333;
$grey: #999999;
$lightGrey: #eff0f2;
$ultraLightGrey: #f6f7f8;

// Refs
$text: $black;
$background: $lightGrey;
$line: $lightGrey;

// Radius
$radius: 2px;

// Margin / Padding
$padding: 20px;

// Fonts
$font: "arial";

// Transitions
$duration: 0.2s;
$quint: cubic-bezier(0.7, 0, 0.3, 1);
$bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutQuad: cubic-bezier(0.46, 0.03, 0.52, 0.96);
